<template>
  <v-card>
    <v-card-title>
      <v-btn
        style="right: 10px; top: 10px"
        icon
        color="primary"
        fab
        small
        absolute
        @click="$emit('closed')"
      >
        <v-icon dark>fa-times</v-icon>
      </v-btn>
  Estructura de datos
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text class="pt-4">
    
    {{sheets}}
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions class="actions-btn">
      <v-btn color="primary" style="border-radius: 10px" @click="success" >Aceptar</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import readXlsxFile from "read-excel-file";
export default {
  name: "matchFileTickets",
  props: ["file"],
  data() {
    return {
      sheets:null,
      requiredFields: [
        {
          name: "Fecha",
          value: "date",
          selectedColumn: 0,
        },
        {
          name: "Hora",
          value: "time",
          selectedColumn: 1,
        },
      ],
      fileColumns: null,
      loading: false,
    };
  },
  methods: {
    success() {
      this.loading = true;
      this.$emit("success", this.requiredFields);
    },
  },
 async mounted() {
     let sheets = await readXlsxFile(this.file, { getSheets: true });
    // console.debug(sheets)
     this.sheets = sheets;
  },
};
</script>

<style lang="scss" scoped>

</style>