<template>
  <div class="BulkLoad">
    <!-- header de titulo y acciones -->
    <headerLayaut
      :subtitle="`Carga de horarios, secciones y entradas del evento ${selectedEvent.name}`"
    >
      <v-img
        :src="selectedEvent.cover.original"
        height="100px"
        max-width="50%"
        style="border-radius: 10px"
      >
      </v-img>
    </headerLayaut>
    <!-- contenido de la pagina -->

    <v-container v-if="!File">
      <v-card class="pt-12 pb-6 mt-12 flex-center" elevation="0">
        <div class="wrap-file">
          <v-file-input
            v-model="File"
            filled
            rounded
            full-width
            prepend-icon="fa-solid fa-file-excel"
            style="border-radius: 10px"
            chips
            @change="uploadFile"
            label="Seleccione el archivo"
          ></v-file-input>
        </div>
      </v-card>
    </v-container>
    <v-container v-else fluid>
      <v-card
        class="mb-12"
        v-for="(page, index) in sheets"
        :key="index"
        elevation="0"
      >
        <v-card-title>
          {{ page.name }}
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-tabs
            v-model="tab"
            centered
            height="40px"
            icons-and-text
            style="background-color: #ffffff00 !important"
          >
            <v-tabs-slider></v-tabs-slider>

            <v-tab href="#tab-1"> Campos del documento </v-tab>

            <v-tab href="#tab-2"> Datos del documento </v-tab>
          </v-tabs>

          <v-tabs-items
            v-model="tab"
            transparent
            style="background-color: #ffffff00 !important"
          >
            <v-tab-item value="tab-1">
              <v-row class="mt-5 px-2">
                <v-col cols="12" sm="4" md="4">
                  <span class="font-weight-bold"> Campos requeridos </span>
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <span class="font-weight-bold">
                    Columnas en el archivo seleccionado
                  </span>
                  <v-divider></v-divider>
                </v-col>
              </v-row>
              <v-row
                class="px-6"
                v-for="(item, index) of requiredFields(page.name)"
                :key="index"
              >
                <v-col cols="12" sm="4" md="4">
                  <p class="mt-5">{{ item.name }}</p>
                </v-col>
                <v-col cols="12" sm="8" md="8">
                  <v-select
                    style="color: #000"
                    v-model="item.selectedColumn"
                    :items="page.fileColumns"
                    label="Seleccionar columna correspondiente"
                    item-text="text"
                    item-value="value"
                    aria-autocomplete="false"
                    filled
                    :loading="loading"
                    rounded
                    :allow-overflow="false"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item value="tab-2">





              
              <table>
                <tr>
                  <td v-for="(f, i) in page.fileColumns" :key="i">
                    <b>{{ f.text }}</b>
                  </td>
                </tr>
                <tr v-for="(data, i) in page.data" :key="i">
                  <td v-for="(d, e) in data" :key="e">{{ d }}</td>
                </tr>
              </table>
            </v-tab-item>
          </v-tabs-items>
        </v-card-text>
        <!-- <v-card-actions justify-end>
          <v-btn color="primary"> Aceptar</v-btn>
        </v-card-actions> -->
      </v-card>
    </v-container>
    <v-container class="flex-end">
      <v-btn
        color="primary"
        outlined
        class="mr-2"
        style="border-radius: 10px"
        @click="File = null"
      >
        Cancelar
      </v-btn>
      <v-btn color="primary" @click="save"> Aceptar </v-btn>
    </v-container>
    <v-dialog v-model="dialog" scrollable width="500px">
      <matchFileTickets :file="File" @closed="dialog = false" />
    </v-dialog>
    <v-dialog v-model="dataValidDialog" width="500px" persistent scrollable>
      <v-card>
        <v-card-title> Validación de datos </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4" v-if="cardValidation">
       
            Rango de fechas permitidas del evento <br />
          {{ selectedEvent.dateStart }} - {{ selectedEvent.dateEnd }}
     
          <v-row>
            <v-col cols="6">
              <h4>Fechas validas</h4>

              <ul class="UlStyle" style="background-color: #2fff0038">
                <li v-for="(i, index) in dataXLSValid" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.date }}</span
                  >
                </li>
              </ul></v-col
            >
            <v-col cols="6">
              <h4>Fechas no permitidas</h4>
              <ul class="UlStyle" style="background-color: #ff000045">
                <li v-for="(i, index) in dataXLSInvalid" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.date }}</span
                  >
                </li>
              </ul></v-col
            >
          </v-row>
        </v-card-text>
        <v-card-text class="pt-4" v-else>
       <b>  Horarios permitidos para guardarlos</b> 
          <v-row>
            <v-col cols="6">
              Horarios

              <ul class="UlStyle" style="background-color: #2fff0038">
                <li v-for="(i, index) in ArrayDataDB" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.date }}</span
                  >
                </li>
              </ul></v-col
            >
            <v-col cols="6">
              Horarios ya existentes

              <ul class="UlStyle" style="background-color: #ff000045">
                <li v-for="(i, index) in ArraySchedule_NO" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.date }}</span
                  >
                </li>
              </ul></v-col
            >
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            text
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="cancelXLSValid"
            >cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="NextSchedule"
            v-if="this.dataXLSValid.length > 0 && cardValidation"
            >Verificar si existen</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="addDBSchedules"
            v-if="ArrayDataDB.length > 0"
            >Guardar Horarios</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="validSecctions"
            v-else-if="ArraySchedule_NO.length > 0"
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="validationSections" max-width="700px">
      <v-card>
        <v-card-title>Validación Secciones</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          Secciones para guardarlos
          <v-row>
            <v-col cols="6">
              <p>Secciones</p>

              <ul class="UlStyle" style="background-color: #00cdbc61">
                <li v-for="(i, index) in SectionsDB" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.name }}</span
                  >
                </li>
              </ul></v-col
            >
            <v-col cols="6">
              Secciones ya existentes
              <ul class="UlStyle" style="background-color: #ff000045">
                <li v-for="(i, index) in SectionsDB_No" :key="index">
                  <span
                    ><b> {{ i.code }}</b> - {{ i.name }}</span
                  >
                </li>
              </ul></v-col
            >
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            text
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="validationSections = false"
            >cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="saveSections"
            v-if="this.SectionsDB.length > 0"
            >Guardar permitidas</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            style="border-radius: 10px"
            @click="CreateTickets"
            v-else-if="SectionsDB_No.length > 0"
            >Continuar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog persistent v-model="CreateTicketsDialog" max-width="700px">
      <v-card>
        <v-card-title>Crear Entradas</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-6">
          Entradas
          <v-row>
            <v-col cols="6" v-for="(i, index) in ticketsDB" :key="index">
              <v-chip outlined>{{ i.name }} </v-chip>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="actions-btn">
          <v-btn
            color="primary"
            text
            @click="CreateTicketsDialog = false"
            :loading="btnLoader"
            style="border-radius: 10px"
            >cancelar</v-btn
          >
          <v-btn
            color="primary"
            :loading="btnLoader"
            @click="entradasSave"
            style="border-radius: 10px"
            >Guardar Entradas</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { mapActions, mapState } from "vuex";
import readXlsxFile from "read-excel-file";
import headerLayaut from "@/components/header-layaut.vue";
import matchFileTickets from "@/components/matchFileTickets.vue";
import { db, fb } from "@/firebase";
import { map } from "leaflet";
export default {
  name: "BulkLoad",

  components: {
    headerLayaut,
    matchFileTickets,
  },
  data() {
    return {
      ArrayDataDB: [],
      AllDataDoc: null,
      SectionsDB: [],
      ArraySchedule_NO: [],
      ticketsDB: [],
      sectionsAll: [],
      datesAll: [],
      SectionsDB_No: [],
      CreateTicketsDialog: false,
      cardValidation: true,
      validationSections: false,
      tab: null,
      btnLoader: false,
      fileColumns: null,
      sheets: null,
      File: null,
      loading: false,
      dialog: false,
      dataXLSValid: [],
      dataXLSInvalid: [],
      dataValidDialog: false,
    };
  },
  computed: {
    ...mapState(["selectedEvent", "user", "business"]),
  },

  methods: {
    ...mapActions(["visibleSearch", "Alert_", "addBusiness", "_searchTerm"]),
    save() {
      for (let i = 0; i < this.sheets.length; i++) {
        const element = this.sheets[i];
        if (element.name === "Horarios") {
          this.addSchedules(element.data);
        }
      }
    },
    async entradasSave() {
      this.btnLoader = true;

      for (let i = 0; i < this.ticketsDB.length; i++) {
        const element = this.ticketsDB[i];

        await db
          .collection("oViveTickets")
          .add(element)
          .then((resp) => {
            //// console.debug(resp);
            this.Alert_({
              text: `Tickets agregada correctamente.`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((error) => {
            this.btnLoader = false;
            //// console.debug("sorry", error);
            this.Alert_({
              text: `Ocurrió un error #${
                i + 1
              } inesperado , inténtelo nuevamente.`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      }
      this.btnLoader = false;
      this.CreateTicketsDialog = false;
      this.validationSections = false;

      this.$router.push("/tickets");
    },
    async saveSections() {
      this.btnLoader = true;
      if (this.SectionsDB.length > 0) {
        for (let i = 0; i < this.SectionsDB.length; i++) {
          const element = this.SectionsDB[i];

          await db
            .collection("events")
            .doc(this.selectedEvent[`.key`])
            .collection("sections")
            .add(element)
            .then((resp) => {
              //// console.debug(resp);
              this.Alert_({
                text: `Sección agregada correctamente.`,
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "#00cdbc",
              });
            })
            .catch((error) => {
              //// console.debug("sorry", error);
              this.Alert_({
                text: `Ocurrió un error #${
                  i + 1
                } inesperado , inténtelo nuevamente.`,
                timeout: 2000,
                btn_closed: true,
                icon: false,
                iconType: "mdi-check",
                type: "error",
              });
            });
        }
        this.btnLoader = false;
        this.validationSections = false;
        this.CreateTickets();
      }
    },
    cancelXLSValid() {
      this.dataValidDialog = false;
      this.dataXLSValid = [];
      this.dataXLSInvalid = [];
    },
    async NextSchedule() {
      this.ArrayDataDB = [];
      this.btnLoader = true;
      //// console.debug(this.dataXLSValid);
      for (let i = 0; i < this.dataXLSValid.length; i++) {
        const element = this.dataXLSValid[i];

        await db
          .collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("dates")
          .where("business", "==", this.business[`.key`])
          .where("code", "==", element.code)
          .get()
          .then((resp) => {
            //// console.debug(resp.docs.length);

            if (resp.docs.length === 0) {
              this.ArrayDataDB.push(element);
            } else {
              this.ArraySchedule_NO.push(element);
            }
          })
          .catch((err) => {});
      }
      this.dataXLSValid = [];
      this.dataXLSInvalid = [];

      if (this.ArrayDataDB.length > 0) {
        this.btnLoader = false;
        this.cardValidation = false;
      } else {
        // comienza a verificar secciones
        this.cardValidation = false;
        this.btnLoader = false;
        // this.validSecctions();
        // this.validationSections = true;
      }
    },
    async addDBSchedules() {
      for (let i = 0; i < this.ArrayDataDB.length; i++) {
        const element = this.ArrayDataDB[i];
        element.business = this.business[`.key`];
        element.createdBy = this.user[`.key`];
        element.createdAt = new Date();
        await db
          .collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("dates")
          .add(element)
          .then((resp) => {
            //// console.debug(resp);
            this.Alert_({
              text: `Horario fue Agregado correctamente.`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "#00cdbc",
            });
          })
          .catch((error) => {
            //// console.debug("sorry", error);
            this.Alert_({
              text: `Ocurrió un error #${
                i + 1
              } inesperado , inténtelo nuevamente.`,
              timeout: 2000,
              btn_closed: true,
              icon: false,
              iconType: "mdi-check",
              type: "error",
            });
          });
      }

      this.validSecctions();
    },
    validSecctions() {
      this.dataValidDialog = false;
      this.validationSections = true;
      for (let i = 0; i < this.sheets.length; i++) {
        const element = this.sheets[i];
        if (element.name === "Secciones") {
          this.SectionsV(element.data);
        }
      }
    },
    async SectionsV(item) {
      this.SectionsDB = [];
      this.SectionsDB_No = [];

      for (let i = 0; i < item.length; i++) {
        const element = item[i];
        var obj = {
          name: element[1],
          showsCounter: element[2] == "SI" ? true : false,
          active: element[3] == "SI" ? true : false,
          amountSold: 0,
          code: element[0],
          business: this.business[`.key`],
          eventId: this.selectedEvent[`.key`],
          isSoldOut: false,
          country: this.selectedEvent.country,
          createdBy: this.user[`.key`],
          createdAt: new Date(),
          isFree: false,
          ochoActive: true,
          deleted: false,
          city: this.selectedEvent.city,
        };

        await db
          .collection("events")
          .doc(this.selectedEvent[`.key`])
          .collection("sections")
          .where("business", "==", this.business[`.key`])
          .where("eventId", "==", this.selectedEvent[`.key`])
          .where("code", "==", obj.code)
          .get()
          .then((resp) => {
            //// console.debug(resp.docs.length);

            if (resp.docs.length === 0) {
              this.SectionsDB.push(obj);
            } else {
              this.SectionsDB_No.push(obj);
            }
          })
          .catch((err) => {});
      }

      if (this.SectionsDB.length > 0) {
        this.btnLoader = false;
        this.validationSections = true;
      } else {
        // comienza a verificar secciones
        this.btnLoader = false;
      }
    },
    CreateTickets() {
      for (let i = 0; i < this.sheets.length; i++) {
        const element = this.sheets[i];
        if (element.name === "Entradas") {
          this.TicketsData(element.data);
        }
      }
    },
    async TicketsData(item) {
      for (let i = 0; i < item.length; i++) {
        const element = item[i];
        const date_id = await this.datesAll.find((d) => d.code === element[6]);
        const section_id = await this.sectionsAll.find(
          (e) => e.code === element[2]
        );

        if (section_id && date_id) {
          var obj = {
            code: element[0],
            name: element[1],
            event: this.selectedEvent[`.key`],
            country: this.selectedEvent.country,
            city: this.selectedEvent.city,
            business: this.business[`.key`],
            maxLimitPerUser: element[7],
            section: section_id[`.key`],
            price: Number(element[5]),
            availableAmount: Number(element[3]),
            amountSold: 0,
            dateStart: this.selectedEvent.dateStart,
            dateEnd: this.selectedEvent.dateEnd,
            salesDateStart: element[14],
            saleTimeStart: element[15],
            salesDateEnd: element[16],
            saleTimeEnd: element[17],
            ticketDate: date_id[`.key`],
            ticketCode: element[8],
            ticketTable: element[9],
            ticketChair: element[10],
            active: element[12] == "SI" ? true : false,
            ochoActive: true,
            deleted: false,
            showsCounter: element[11] == "SI" ? true : false,
            isSoldOut: element[13] == "SI" ? true : false,
            isFree: element[4] == "SI" ? true : false,
            createdAt: new Date(),
            createdBy: this.user[`.key`],
            // modifiedAt: new Date(),
            // modifiedBy: this.user[`.key`],
            // deletedAt: new Date(),
            // deletedBy: this.user[`.key`],
          };
         // console.debug(obj);
          await this.ticketsDB.push(obj);
          this.CreateTicketsDialog = true;
         console.warn(`----------------- ${i} -----------------`);
         console.warn(date_id);
         console.warn(section_id);
        }
      }
     // console.debug("fin");
    },

    async addSchedules(item) {
      //// console.debug(item);
      var scheduleDB = [];
      for (let i = 0; i < item.length; i++) {
        const element = item[i];
        var obj = {
          code: element[0],
          active: element[3] == "SI" ? true : false,

          deleted: false,
          date: element[1],
          time: element[2],
        };

        if (
          element[1] >= this.selectedEvent.dateStart &&
          element[1] <= this.selectedEvent.dateEnd
        ) {
          //// console.debug("si");
          this.dataXLSValid.push(obj);
        } else {
          //// console.debug("no");
          this.dataXLSInvalid.push(obj);
          scheduleDB.push(obj);
        }
      }
      this.dataValidDialog = true;
    },
    async uploadFile(e) {
      //// console.debug(e);
      let sheets = await readXlsxFile(this.File, { getSheets: true });

      for (let i = 0; i < sheets.length; i++) {
        const element = sheets[i];
        let fileColumns;
        //// console.debug(element.name);
        await readXlsxFile(this.File, { sheet: i + 1 }).then((rows) => {
          //// console.debug("rows", rows);
          fileColumns = rows[0].map((item, index) => {
            if (item) {
              return {
                value: index,
                text: item,
              };
            }
          });
          //console.warn(fileColumns);
          fileColumns.filter((item) => item != "undefined" || item);

          element.fileColumns = fileColumns;

          //console.warn(element);
          element.data = rows;
          element.data.shift();
        });
        //// console.debug(element);
      }
      this.sheets = sheets;
    },
    requiredFields(name) {
      switch (name) {
        case "Horarios":
          return [
            {
              name: "ID HORARIO",
              value: "ID HORARIO",
              selectedColumn: 0,
            },
            {
              name: "Fecha",
              value: "Fecha",
              selectedColumn: 1,
            },
            {
              name: "Hora",
              value: "Hora",
              selectedColumn: 2,
            },
            {
              name: "Activo",
              value: "Activo",
              selectedColumn: 3,
            },
          ];
          break;
        case "Secciones":
          return [
            {
              name: "ID SECCIÓN",
              value: "ID SECCIÓN",
              selectedColumn: 0,
            },
            {
              name: "Nombre",
              value: "Nombre",
              selectedColumn: 1,
            },
            {
              name: "Muestra el contador",
              value: "Muestra el contador",
              selectedColumn: 2,
            },
            {
              name: "Activo",
              value: "Activo",
              selectedColumn: 3,
            },
          ];
          break;
        case "Entradas":
          return [
            { name: "ID ENTRADA", value: "ID ENTRADA", selectedColumn: 0 },
            { name: "Nombre", value: "Nombre", selectedColumn: 1 },
            { name: "Sección", value: "Sección", selectedColumn: 2 },
            { name: "cantidad", value: "cantidad", selectedColumn: 3 },
            { name: "Es gratis", value: "Es gratis", selectedColumn: 4 },
            { name: "Precio", value: "Precio", selectedColumn: 5 },
            { name: "Fecha y hora", value: "Fecha y hora", selectedColumn: 6 },
            {
              name: "limite de venta por usuario",
              value: "limite de venta por usuario",
              selectedColumn: 7,
            },
            { name: "#de entrada", value: "#de entrada", selectedColumn: 8 },
            { name: "#mesa", value: "#mesa", selectedColumn: 9 },
            { name: "#silla", value: "#silla", selectedColumn: 10 },
            {
              name: "Muestra contador",
              value: "Muestra contador",
              selectedColumn: 11,
            },
            { name: "Activo", value: "Activo", selectedColumn: 12 },
            { name: "Esta agotado", value: "Esta agotado", selectedColumn: 13 },
            {
              name: "Fecha inicio Venta",
              value: "Fecha inicio Venta",
              selectedColumn: 14,
            },
            {
              name: "Hora inicio Venta",
              value: "Hora inicio Venta",
              selectedColumn: 15,
            },
            {
              name: "Fecha final Venta",
              value: "Fecha final Venta",
              selectedColumn: 16,
            },
            {
              name: "Hora final Venta",
              value: "Hora final Venta",
              selectedColumn: 17,
            },
          ];
          break;

        default:
          return [];
          break;
      }
    },
  },
  mounted() {
    this._searchTerm("Boletos vendidos");
    this.visibleSearch(true);
    this.$binding(
      "datesAll",
      db.collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("dates")
        .where("business", "==", this.business[`.key`])
        .where("active", "==", true)
    );
    this.$binding(
      "sectionsAll",
      db.collection("events")
        .doc(this.selectedEvent[`.key`])
        .collection("sections")
        .where("business", "==", this.business[`.key`])
        .where("eventId", "==", this.selectedEvent[`.key`])
        .where("active", "==", true)
    );
  },
};
</script>
<style lang="scss" scoped>
.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.wrap-file {
  width: 300px;
}
table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #dddddd0f;
  overflow: hidden;
}

td,
th {
  border: 1px solid #dddddd0f;
  text-align: left;
  padding: 8px;
  font-size: 12px;
}

tr:nth-child(even) {
  background-color: #ffffff09;
}
.theme--dark.v-tabs > .v-tabs-bar {
  background-color: #1e1e1e00 !important;
}
.UlStyle {
  border-radius: 10px;
  padding: 10px;
  li {
    list-style: none;
  }
}
</style>
